var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('PageWrapDefault',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"errorMessage":_vm.error,"successMessage":_vm.message,"showSearch":false,"topActionButtonSecondary":_vm.secondaryToolbar,"topActionButtonPrimary":_vm.primaryToolbar},on:{"toolbarSelectItem":_vm.onToolbarItemSelected,"errorMessageClosed":function($event){_vm.error = ''}}},[_c('div',{staticClass:"flex items-center justify-end gap-10 mb-4"},[_c('div',{staticClass:"font-semibold",staticStyle:{"color":"#034b9d"}},[_c('router-link',{attrs:{"to":"/payplanchangerequests"}},[_c('span',[_vm._v("Looking for Pay Plan Change? ")])])],1),(
        _vm.editing &&
          _vm.editing.data &&
          _vm.editing.data.insured &&
          _vm.editing.data.insured.lastName &&
          _vm.editing.data.insured.firstName
      )?_c('div',{staticClass:"flex items-end justify-end cursor-pointer text-base text-atlas-blue hover:font-medium font-semibold",on:{"click":_vm.editPolicyInsuredInfo}},[_c('div',[_c('i',{staticClass:"el-icon-edit font-extrabold"}),_vm._v("Edit")])]):_vm._e()]),_c('custom-alert',{attrs:{"title":"Please Note","message":"Property must not be in foreclosure to use this form.","show":_vm.showForeclosureAlert},on:{"dismiss":function($event){_vm.showForeclosureAlert = false}}}),(
      _vm.insuredInfo.isExempted &&
        _vm.editing?.data?.isLapsed &&
        !_vm.editing?.attachments?.length
    )?_c('custom-alert',{attrs:{"title":"Please Note","message":'Policy is lapsed and would require documentation supporting the new cancellation date before submission.',"show":true,"dismissible":false}}):_vm._e(),(
      _vm.editing &&
        _vm.editing.data &&
        _vm.editing.data.policyInfo &&
        !_vm.insuredInfo.changeRequestMade
    )?_c('PolicyInfo',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingText),expression:"loadingText"}],ref:"propertyChangeReqPolicyInfoComponentRef",attrs:{"element-loading-text":_vm.loadingText,"policyInfo":_vm.editing.data.policyInfo,"disabled":_vm.disablePolicyInfoComponent,"isChangeRequest":true,"effectiveDateLabel":"Cancellation Effective Date"},on:{"updateChangeRequestData":function($event){return _vm.handleChangeRequestCreation($event, 'data.policyInfo')},"paddedPolicyNumber":_vm.setPaddedPolicyNumber,"setLoadingText":function($event){_vm.loadingText = $event},"sendBackInsuredInfo":_vm.setInsuredInfo,"sendBackInsuredMailingAndLocAddress":_vm.setMailingAndLocAddresses}}):_vm._e(),(
      _vm.editing &&
        _vm.editing.data.insured.lastName &&
        _vm.editing.data.insured.firstName
    )?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingText),expression:"loadingText"}],attrs:{"element-loading-text":_vm.loadingText}},[_c('ChangeRequestInsured',{attrs:{"type":"new","insuredData":_vm.editing?.data.insured,"changeRequestType":"BILLING_REQUEST"}}),_c('div',{staticClass:"mb-10"},[_c('h3',{staticClass:"text-lg leading-6 font-medium text-gray-900"},[_vm._v(" Cancellation Change Request Actions ")])]),_c('el-collapse',{staticClass:"mb-10",model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[_c('el-tooltip',{attrs:{"content":"Property must not be in foreclosure to use this form.","placement":"top","effect":"light"}},[_c('el-collapse-item',{attrs:{"title":"Cancellation Request","name":"1"}},[_c('div',{staticClass:"pb-3"},[_c('label',{staticClass:"pr-4",attrs:{"for":""}},[_vm._v("Cancellation Reason: ")]),_c('el-radio-group',{on:{"handleChange":function($event){return _vm.handleChangeRequestCreation({
                  fieldKey: 'data.billing.reason',
                  value: _vm.CancellationReasonRadioButton
                })}},model:{value:(_vm.CancellationReasonRadioButton),callback:function ($$v) {_vm.CancellationReasonRadioButton=$$v},expression:"CancellationReasonRadioButton"}},[_c('el-radio',{attrs:{"label":"coverageElsewhere"}},[_vm._v("Coverage Elsewhere")]),_c('el-radio',{attrs:{"label":"propertySold"}},[_vm._v("Property Sold")]),_c('el-radio',{attrs:{"label":"other"}},[_vm._v("Other")])],1)],1),_c('div',[(_vm.CancellationReasonRadioButton === 'other')?_c('div',[_c('div',{staticClass:"pb-5"},[_c('label',[_vm._v("Additional Remarks:")])]),_c('div',[_c('TextField',{ref:"field_AdditionalRemarks",staticClass:"w-full",attrs:{"fixedLabels":true,"required":_vm.CancellationReasonRadioButton === 'other',"label":"(Required if OTHER selected)","placeholder":"Additional Remarks","value":_vm.editing?.data?.billing?.other},on:{"input":function($event){return _vm.handleChangeRequestCreation({
                      fieldKey: 'data.billing.other',
                      value: $event
                    })}}})],1)]):_vm._e()]),_c('div',[_c('el-checkbox',{attrs:{"checked":_vm.editing?.data?.billing?.agentCertify},on:{"change":function($event){return _vm.handleChangeRequestCreation({
                  fieldKey: 'data.billing.agentCertify',
                  value: _vm.agentCertifyCheckBox
                })}},model:{value:(_vm.agentCertifyCheckBox),callback:function ($$v) {_vm.agentCertifyCheckBox=$$v},expression:"agentCertifyCheckBox"}},[_vm._v("By checking this box agent certifies they have possession of a signed cancellation request from the insured.")])],1)])],1),_c('el-collapse-item',{attrs:{"title":"Document Upload","name":"2"}},[_c('ChangeRequestFileUpload',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"disabled":!_vm.editing?.data?.billing?.reason,"formType":"propertyChangeRequest","attachments":_vm.editing.attachments,"isChangeRequest":_vm.insuredInfo.isExempted || _vm.editing?.data?.isLapsed},on:{"upload-success":_vm.attachmentUploadHandler,"delete-attachment":_vm.attachmentDeleteHandler}})],1)],1),_c('div',{staticClass:"mb-10"},[_c('label',{attrs:{"for":""}},[_vm._v("Remarks")]),_c('el-input',{attrs:{"type":"textarea","placeholder":"Please input","value":_vm.editing?.data?.billing?.remarks,"maxlength":"395","show-word-limit":"","clearable":true},on:{"input":function($event){return _vm.handleChangeRequestCreation({
            fieldKey: 'data.billing.remarks',
            value: $event
          })}}})],1),(_vm.editing.status)?_c('span',{staticClass:"mb-20",staticStyle:{"float":"right"},attrs:{"slot":"header"},slot:"header"},[_vm._v("Status : "+_vm._s(`${_vm.billingChangeRequestId ? "Saved" : _vm.editing.status}`))]):_vm._e()],1):_vm._e(),_c('ModalMessage',{attrs:{"name":"lapsedPolicyModal","icon":['fal', 'exclamation-circle'],"iconClass":"text-red-600 text-6xl","title":"Lapsed Policy","description":_vm.modalMessage,"buttons":[
      {
        text: `Yes`,
        classList: ['border-atlas-blue bg-white text-atlas-blue'],
        click: () => _vm.handleModalButtonYesClick()
      },
      {
        text: `No`,
        click: () => _vm.handleModalButtonNoClick()
      }
    ]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }